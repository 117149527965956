
(function () {

 new WOW().init();


})();


    var a = 0;
    window.onscroll = function() {
        $('.count.animated').each(function() {
            var $this = $(this),
              countTo = $this.attr('data-count');
            $({
              countNum: $this.text()
            }).animate({
                countNum: countTo
              },
      
              {
      
                duration: 4000,
                easing: 'swing',
                step: function() {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function() {
                  $this.text(this.countNum);
                  //alert('finished');
                }
      
              });
          });
          a = 1;
    };  
    
    var lFollowX = 0,
    lFollowY = 0,
    x = 0,
    y = 0,
    friction = 1 / 300;

    function moveBackground() {
        x += (lFollowX - x) * friction;
        y += (lFollowY - y) * friction;
        
        translate = 'rotate(' + x + 'deg)';

        $('.bg-rotat').css({
            '-webit-transform': translate,
            '-moz-transform': translate,
            'transform': translate
        });

        window.requestAnimationFrame(moveBackground);
        }

        $(window).on('mousemove click', function(e) {

        var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
        var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
        lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
        lFollowY = (10 * lMouseY) / 100;

    });

    moveBackground();

    


$(document).on('click', '.member-list-item a', function(){


    
    var url = $(this).find('img').attr('src'),
    name = $(this).attr('data-name'),
    pos = $(this).attr('data-pos');
    if (url != $('#memberImg').attr('src').replace(/\?(.*)/,'')) {
        $('.member-list-item a').removeClass('active');
        $(this).addClass('active');
        $('#memberImg').addClass('fadeout');
        setTimeout(() => {
            $("#memberImg").attr("src", url);
            $('#memberImg').removeClass('fadeout');
        }, 1000);
        
        document.getElementById("memberName").innerHTML = name;
        document.getElementById("memberPosition").innerHTML = pos;
    }
    return false;

    
});

// $('.member-list-item a').each(function(){
//     $(this).click(function(){
//         var src = $(this).attr('href');
        
//         if (src != $('#memberImg').attr('src').replace(/\?(.*)/,'')) {
//             $('#memberImg').stop().animate({opacity:'0'},function(){
//                     $(this).attr('src',src+'?'+Math.floor(Math.random()*(10*1000)));
//             }).load(function(){
//                     $(this).stop().animate({opacity:'1'});
//             });
//         }
//         return false;
//     });
// });



